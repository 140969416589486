@import "node_modules/bulma/bulma";

body {
  width: 100%;
  padding-top: 1.2rem;
  padding-bottom: 0.4rem;
  padding-left: 8px;
  padding-right: 8px;
}

.result-indicator {
  background: rgb(153, 122, 122);
  min-height: 24px;
  color: white;
}

.searchResult {
  padding-top: 0.8rem;
}

.records {

  .is-emphasize {
    border-bottom: 1px solid rgba(250, 20, 20, 0.6);
  }
  
  .record {
    // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;

    padding-top: 0.8rem;
    padding-bottom: 1.0rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    border-right: 0px;

    overflow: hidden;
  
    // &:hover {
    //   background-color: rgba(155, 155, 255, 0.15);
    // }

    &:not(:last-of-type) {
      border-style: solid;
      border-image: linear-gradient(to right, white 0%, lightgray 8%, lightgray 90%, white 100%) 1/0 0 1px 0;
    }

    .main-title {
      padding-bottom: 0.6rem;
      line-height: 1;

      .id {
        vertical-align: middle;
      }

      .genre {
        vertical-align: middle;
        font-size: 0.8rem;
        font-weight: bold;
        color: darkslategrey;
      }

      .title {
        vertical-align: middle;
        font-size: 1.2rem;
        color: gray;
      }

      .subtitle {
        vertical-align: middle;
        font-size: 0.85rem;
        color: gray;
        margin-left: 1rem;
      }
    }


    &.is-fullfts {
      background: linear-gradient(105deg, rgba(6,99,171,0.1) 0%, rgba(34,112,196,0.1) 15%, rgba(0,123,255,0) 100%);

      .title {
        font-weight: bold;
        color: rgb(184, 19, 13);
      }
    }

    .tag-parade {
      // padding-left: 0.4rem;

      input[type="text"][disabled] {
        background: transparent;
        color: black;
        cursor: default;
        border: none;

        padding-left: 0.25em;
        padding-right: 0.25em;

        text-align: right;

        font-size: 1rem;
        width: 4.2em;
      }

      .tag {
        margin-bottom: 0.2rem;

        &:hover {
          background-color: rgba(67, 20, 80, 0.6);
        }

      }

      .bias {
        color: white;
        background-color: rgba(89, 57, 175, 0.8);
      }

      .contid {
        color: white;
        background-color: rgba(155, 38, 11, 0.8);
      }

      .bookid {
        color: white;
        background-color: rgba(36, 36, 36, 0.8);
      }

      .author {
        background-color: rgba(50, 152, 220, 0.4);
      }

      .prop {
        background-color: rgba(141, 170, 104, 0.4);
      }

      .jpub {
        background-color: rgba(141, 70, 54, 0.4);
      }

      .midpub {
        background-color: rgba(212, 136, 219, 0.4);
      }

      .lang {
        background-color: rgba(151, 135, 64, 0.4);
      }

      .key {
        background-color: rgba(141, 70, 54, 0.4);
      }

      .cont {
        color: white;
        background-color: rgba(155, 38, 11, 0.8);
      }

      .hits {
        color: white;
        background-color: rgba(25, 151, 67, 0.8);
      }

      .tag .entry {
        margin-right: 0.4em;

        padding-right: 0.4em;
        padding-left: 0.4em;

        border-radius: 4px;
        border: 1px dashed;
      }
    }
  }
}

@keyframes copyFeedback {
  0% {
    transform: scale(1.0);
    opacoty: 1.0;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}

.countIndicator {
  color: rgba(0, 0, 0, 0.4);

  font-size: 0.8rem;
}
